











































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
    props: {
        name: { type: String, required: true },
        viewMode: { type: String, required: true },
        isReviewing: { type: Boolean, required: false, default: false },
        isFlagged: { type: Boolean, required: false, default: false },
        isChecked: { type: Boolean, required: false, default: false },
        flagsCount: { type: Number, required: false, default: 0 },
        type: { type: String, required: false, default: "section" },
        isSaving: { type: Boolean, required: false, default: false },
    },
    computed: {
        ...mapGetters({
            companyData: "company/getProfile",
            profileData: "auth/getUserData",
        }),
        canReviewSection(): boolean {
            return ["reviewing", "certifying"].includes(this.viewMode);
        },
        isFRCNTeamMember() {
            return this.profileData.regulator === "Financial Reporting Council of Nigeria";
        },
    },
});
