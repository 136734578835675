

























































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { formatDate, formatDateToLocale } from "@/utils/helpers";

export default Vue.extend({
    name: "ReportCertification",
    props: {
        report: { type: Object, required: true },
        penalties: { type: Array, required: false, default: () => [] },
    },
    data() {
        return {
            DateTime,
        };
    },
    computed: {
        companyName(): string {
            return this.report.reportInformation.companyName;
        },
        rcNumber(): string {
            return this.report.reportInformation.rcNumber;
        },
        dateFiled(): string {
            return this.report.reportInformation.dateSentToRegulator;
        },
        startDate(): string {
            return this.report.reportInformation.reportingTimeLineFrom;
        },
        endDate(): string {
            return this.report.reportInformation.reportingTimeLineTo;
        },
        principles(): any {
            return this.report.sectionReviews.filter((review: any) => review.name.includes("principle"));
        },
        totalNumberOfCompliantPrinciples(): number {
            return this.principles.filter((review: any) => review.complianceStatus === "Compliant" || review.complianceStatus === "PartiallyCompliant").length;
        },
        complianceStatus(): string {
            let status = "";
            if (this.numberOfCompliantPrinciples > this.numberOfNonCompliantPrinciples) {
                status = "Compliant";
            } else if (this.numberOfNonCompliantPrinciples > this.numberOfCompliantPrinciples) {
                status = "Not compliant";
            } else {
                status = "Partially compliant";
            }
            return status;
        },
        complianceLevel(): number {
            return Math.floor((this.totalNumberOfCompliantPrinciples / 28) * 100);
        },
        compliacePunctuality(): string {
            let punctuality = "";
            const endDate = this.getMonthAndYear(this.endDate);
            const dateFiled = this.getMonthAndYear(this.dateFiled);
            if (dateFiled.getTime() > endDate.getTime()) {
                punctuality = "Late Submission";
            } else if (dateFiled.getTime() < endDate.getTime()) {
                punctuality = "Proactive";
            } else {
                punctuality = "Punctual";
            }
            return punctuality;
        },
        numberOfCompliantPrinciples(): number {
            return this.principles.filter((review: any) => review.complianceStatus === "Compliant").length;
        },
        numberOfNonCompliantPrinciples(): number {
            return this.principles.filter((review: any) => review.complianceStatus === "NonCompliant").length;
        },
        numberOfPartiallyCompliantPrinciples(): number {
            return this.principles.filter((review: any) => review.complianceStatus === "PartiallyCompliant").length;
        },
        havePenalties(): boolean {
            return this.penalties && this.penalties.length > 0;
        },
        penaltiesTotalAmount(): string {
            return Number(
                this.penalties.reduce((accumulator, current: any) => {
                    return Number(accumulator) + Number(current.amount);
                }, 0),
            ).toLocaleString();
        },
        ...mapGetters({
            profileData: "auth/getUserData",
            files: "file/getFiles",
        }),
        profileSignatureId(): string {
            const { signatureImageId } = this.profileData;
            return signatureImageId;
        },
        profileImageUrl(): string {
            const imageObj = this.files[this.profileSignatureId];
            return imageObj && imageObj.presignedUrl;
        },
    },
    async mounted() {
        if (this.profileSignatureId) await this.getFileUrl(this.profileSignatureId);
    },
    watch: {
        async profileSignatureId(newValue) {
            await this.getFileUrl(newValue);
        },
    },
    methods: {
        formatDate,
        formatDateToLocale,

        getMonthAndYear(date: string) {
            const year = new Date(date).getFullYear();
            const month = new Date(date).getMonth();
            return new Date(year, month);
        },

        convertPenaltyIncident(incident: string) {
            if (incident.includes("principle")) {
                const incidentNumber = incident.replace("principle", "");
                return `Principle ${incidentNumber}`;
            }
            return incident;
        },

        ...mapActions({
            fetchFile: "file/fetchFile",
        }),

        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", { title: "Unable to fetch file!", variant: "error", solid: true });
            }
        },
    },
});
