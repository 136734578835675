var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-title"},[_c('span',{staticClass:"text-uppercase ml-1"},[_vm._v(_vm._s(_vm.principleInput.uniqueName))]),(_vm.principleInput.innerOrderIndex > 0)?_c('span',[_vm._v("("+_vm._s(String.fromCharCode(96 + _vm.principleInput.innerOrderIndex))+")")]):_vm._e()]),_c('section',{staticClass:"border border-gray-20 rounded-8 p-3"},[_c('div',[_c('div',{staticClass:"entry-text mb-4",domProps:{"innerHTML":_vm._s(_vm.mappedInputModel.entry)}}),(
                    _vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN) ||
                    _vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT) ||
                    _vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA)
                )?_c('div',[_c('p',{staticClass:"heading mb-1"},[_vm._v("Answer")]),_c('span',{staticClass:"sub-text"},[_vm._v(_vm._s(_vm.mappedInputModel.booleanValue ? "Yes" : "No"))])]):_vm._e()]),(
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.LONGTEXT) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.TEXT) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.TEXT_AND_DATEPICKER) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.LONGTEXT_AND_MEDIA)
            )?_c('div',[_c('p',{staticClass:"heading mb-1 mt-3"},[_vm._v("Explanation")]),_c('span',{staticClass:"sub-text"},[_vm._v(_vm._s(_vm.mappedInputModel.stringValue || "No Input provided"))])]):_vm._e(),(_vm.mappedInputModel.type.includes(_vm.IQuestionType.NUMBER))?_c('div',[_c('p',{staticClass:"heading mb-1"},[_vm._v("Answer")]),_c('span',{staticClass:"sub-text"},[_vm._v(_vm._s(_vm.mappedInputModel.numericValue || "No Input provided"))])]):_vm._e(),(
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.DATEPICKER) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.TEXT_AND_DATEPICKER) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.DROPDOWN_AND_DATEPICKER)
            )?_c('div',[_c('p',{staticClass:"heading mb-1"},[_vm._v("Date of last review")]),_c('span',{staticClass:"sub-text"},[_vm._v(_vm._s(_vm.formatDate(_vm.mappedInputModel.dateValue, _vm.DateTime.DATE_SHORT) || "No Input provided"))])]):_vm._e(),(
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.MEDIA) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA) ||
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.LONGTEXT_AND_MEDIA) ||
                true
            )?_c('div',{staticClass:"mt-3"},[_c('p',{staticClass:"heading mb-1"},[_vm._v("Supporting document")]),_vm._l((_vm.mappedInputModel.attatchments),function(attachment,idx){return _c('ba-file-input',{key:idx,attrs:{"noLabel":"","noDivider":"","readonly":"","disabled":"","fileName":attachment.fileName,"fileId":attachment.docUploadId,"size":attachment.size,"label":''}})})],2):_vm._e(),(_vm.mappedInputModel.attatchments.length < 1)?_c('p',{staticClass:"sub-text"},[_vm._v("Not Provided")]):_vm._e(),_c('div',{staticClass:"mt-4 d-flex"},[(_vm.showButtons)?_c('div',{staticClass:"d-flex"},[(!_vm.mappedInputModel.isFlagged)?_c('ba-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"danger","text":"Flag","loading":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.resolveFlagPrinciple(true)}}}):_c('ba-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"loading":_vm.isLoading,"variant":"success","text":"Resolve Flag (Approve)"},on:{"click":function($event){$event.preventDefault();return _vm.resolveFlagPrinciple(false)}}}),_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.toggleFlagPrinciple({ flag: false })}}},[_vm._v(" "+_vm._s(_vm.mappedInputModel.flagReason ? "Update" : "Add")+" Comment ")])],1):_vm._e(),(_vm.mappedInputModel.flagReason)?_c('b-button',{staticClass:"text-white px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){_vm.$emit('inputModel', {
                        description: ("View comment on Question " + (_vm.romanize(_vm.principleInput.orderIndex)) + " " + (_vm.principleInput.innerOrderIndex > 0 ? String.fromCharCode(96 + _vm.principleInput.innerOrderIndex) : '')),
                        comment: _vm.mappedInputModel.flagReason,
                    })}}},[_vm._v("View Comment")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }