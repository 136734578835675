














import Vue from "vue";

export default Vue.extend({
    name: "ReviewConfirmationModal",
    props: {
        isLoading: { type: Boolean, required: false, default: false },
    },
});
