

































import Vue from "vue";

export default Vue.extend({
    props: {
        showReviewed: { type: Boolean, required: false, default: false },
        showChecked: { type: Boolean, required: false, default: false },
        showFlagged: { type: Boolean, required: false, default: false },
        showToggler: { type: Boolean, required: false, default: false },
        toggleText: { type: String, required: false, default: "Show" },
        reviews: { type: Array, required: true },
    },
    computed: {
        numberOfFlaggedSection(): number {
            return this.reviews.filter((section: any) => section.status === "flagged").length;
        },
        numberOfCheckedSection(): number {
            return this.reviews.filter((section: any) => section.status === "checked").length;
        },
        unreviewedSections(): any {
            return this.reviews.filter((section: any) => section.status === "unreviewed");
        },
    },
});
