














import Vue from "vue";
import ReportService from "@/api/report-service";

export default Vue.extend({
    name: "SendBackModal",
    data() {
        return {
            isLoading: false,
            comment: "",
        };
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-send-back");
        },

        async sendBackToReviewer() {
            if (this.comment === "") {
                this.$bvToast.toast("Add a message to your decision so the receiver can make changes", { title: "Something is not right", solid: true, variant: "danger" });
                return;
            }
            const { id } = this.$route.params;
            const body = { comment: this.comment };
            try {
                this.isLoading = true;
                const response = await ReportService.sendBackToReviewer(id, body);
                if (response && response.status === 200) {
                    this.comment = "";
                    this.$bvToast.toast("Report sent back to Reviewer successfully.", { title: "Success", solid: true, variant: "success" });
                    this.closeModal();
                    this.$emit("report-sent-back");
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
    },
});
