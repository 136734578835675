





















































































import Vue from "vue";
import QuestionOutput from "@/app/components/reports/QuestionOutput.vue";
import ReportSection from "@/app/components/tabs/view-report/Section.vue";

export default Vue.extend({
    props: {
        isLoading: { type: Boolean, default: false },
        viewMode: { type: String, required: true },
        isSaving: { type: Boolean, required: false, default: false },
        report: { type: Object, default: () => ({}) },
        haveStartedReviewing: { type: Boolean, default: false },
        reviews: { type: Array, required: true, default: () => [] },
    },
    components: { QuestionOutput, ReportSection },
    computed: {
        sectionIsFlagged() {
            return (principleId: string): boolean => {
                const section = this.reviews.find((review: any) => review.id === principleId) as any;
                return section.status === "flagged" && section.flags.length > 0;
            };
        },
        sectionIsChecked() {
            return (principleId: string): boolean => {
                const section = this.reviews.find((review: any) => review.id === principleId) as any;
                return section.status === "checked";
            };
        },
        numberOfFlags() {
            return (principleId: string): number => {
                const section = this.reviews.find((review: any) => review.id === principleId) as any;
                return section.flags.length;
            };
        },
    },
    data() {
        return {
            principlesComplianceStatus: {
                "Principle 1": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 2": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 3": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 4": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 5": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 6": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 7": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 8": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 9": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 10": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 11": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 12": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 13": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 14": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 15": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 16": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 17": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 18": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 19": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 20": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 21": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 22": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 23": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 24": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 25": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 26": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 27": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
                "Principle 28": {
                    status: "",
                    explanationProvided: false,
                    documentProvided: false,
                },
            } as { [key: string]: { status: string; explanationProvided: boolean; documentProvided: boolean } },
        };
    },
    methods: {
        selectPartiallyCompliantReason(event: any) {
            const principleHaveFlags = this.checkIfPrincipleHaveFlags(event.sectionId);

            const currentComplianceStatuses = {
                explanationProvided: this.principlesComplianceStatus[event.sectionName].explanationProvided,
                documentProvided: this.principlesComplianceStatus[event.sectionName].documentProvided,
            };

            const payload = { ...currentComplianceStatuses, ...event, status: principleHaveFlags ? "flagged" : "checked", complianceStatus: "partiallycompliant" };
            this.$emit("check-section", payload);
        },

        changePrincipleComplianceStatus(complianceStatus: string, principleId: string, principleName: string) {
            const principleHaveFlags = this.checkIfPrincipleHaveFlags(principleId);

            if (complianceStatus !== "partiallycompliant") {
                this.principlesComplianceStatus[principleName].documentProvided = false;
                this.principlesComplianceStatus[principleName].explanationProvided = false;
            }

            if (complianceStatus === "noncompliant" && !principleHaveFlags) {
                const radioButton = document.getElementById(`non-compliant-${principleName}`);
                (radioButton as HTMLInputElement).checked = false;
                this.$bvToast.toast("A section that has not been flagged cannot be non compliant", { title: "Error", variant: "danger", solid: true });
                this.principlesComplianceStatus[principleName].status = "";
                return;
            }

            if (complianceStatus === "compliant") {
                this.$emit("check-section", { sectionId: principleId, complianceStatus, status: principleHaveFlags ? "flagged" : "checked" });
            } else if (complianceStatus === "noncompliant") {
                this.$emit("check-section", { sectionId: principleId, complianceStatus, status: "flagged" });
            }
        },

        checkIfPrincipleHaveFlags(principleId: string) {
            const section = this.reviews.find((review: any) => review.id === principleId) as any;
            return section.flags.length > 0;
        },
    },
    mounted() {
        this.reviews
            .filter((review: any) => review.type === "principle")
            .forEach((review: any) => {
                this.principlesComplianceStatus[review.name].status = review.complianceStatus;
                if (review.complianceStatus.toLowerCase() === "partiallycompliant") {
                    this.principlesComplianceStatus[review.name].documentProvided = review.documentProvided;
                    this.principlesComplianceStatus[review.name].explanationProvided = review.explanationProvided;
                }
            });
    },
});
