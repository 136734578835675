










import Vue from "vue";

export default Vue.extend({
    name: "SectionStatus",
    props: {
        section: { type: Object, required: true },
        showButton: { type: Boolean, required: false, default: false },
    },
});
