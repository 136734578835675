




















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ReportSection from "@/app/components/tabs/view-report/Section.vue";

export default Vue.extend({
    components: { ReportSection },
    props: {
        isLoading: { type: Boolean, default: false },
        viewMode: { type: String, required: true },
        isSaving: { type: Boolean, required: false, default: false },
        report: { type: Object, default: () => ({}) },
        haveStartedReviewing: { type: Boolean, default: false },
        reviews: { type: Array, required: true, default: () => [] },
    },
    computed: {
        ...mapGetters({ files: "file/getFiles" }),
        mappedSignatoriesPositions(): any {
            const mapped = this.signatoriesPositions.map((signatoryPosition: any) => {
                return {
                    ...signatoryPosition,
                    details: this.getSignature(signatoryPosition.normalizedPosition),
                };
            });
            mapped.forEach(async (signatoryPosition: any) => {
                if (signatoryPosition.details.signatureUploadId) {
                    await this.getFileUrl(signatoryPosition.details.signatureUploadId);
                }
            });
            return mapped;
        },
        sectionData(): any {
            const section = this.reviews.find((review: any) => review.id === "execution") as any;
            return section;
        },
        sectionIsFlagged(): boolean {
            return this.sectionData.status === "flagged" && this.sectionData.flags.length > 0;
        },
        sectionIsChecked(): boolean {
            return this.sectionData.status === "checked";
        },
        numberOfFlags(): number {
            return this.sectionData.flags.length;
        },
    },
    data() {
        return {
            signatoriesPositions: [
                // {
                //     position: "Managing Director/Chief Executive officer",
                //     normalizedPosition: "SN1",
                //     description: "",
                //     isRequired: true,
                //     selected: [],
                //     flaggedName: "managingDirector",
                //     flaggedDescription: "View comment on Managing director",
                // },
                // {
                //     position: "Chairman of the Board of Director",
                //     normalizedPosition: "SN2",
                //     description: "",
                //     isRequired: true,
                //     selected: [],
                //     flaggedName: "chairmanOfBoard",
                //     flaggedDescription: "View comment on Chairman of board",
                // },
                {
                    position: "Board Chairman or Chairman, Committee Responsible For Governance",
                    normalizedPosition: "SN3",
                    description: "This is the head of the department",
                    isRequired: true,
                    selected: [],
                    flaggedName: "chairmanOfCommittee",
                    flaggedDescription: "View comment on Chairman of committee",
                },
                {
                    position: "Company Secretary or Compliance officer",
                    normalizedPosition: "SN4",
                    description: "",
                    isRequired: true,
                    selected: [],
                    flaggedName: "companySecretary",
                    flaggedDescription: "View comment on company secretary",
                },
            ],
        };
    },
    methods: {
        ...mapActions({ fetchFile: "file/fetchFile" }),
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", { title: "Unable to fetch file!", variant: "error", solid: true });
            }
        },
        getSignatureUrl(signId: string): string {
            const imageObj = this.files[signId];
            return imageObj && imageObj.presignedUrl;
        },
        getSignature(normalizedPosition: string) {
            return this.report?.signatures?.find((signature: any) => signature.normalizedPosition === normalizedPosition) || false;
        },
    },
});
