














































































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import ReportService from "@/api/report-service";
import ReportCertification from "../review/ReportCertification.vue";

export default Vue.extend({
    components: { ReportCertification },
    name: "CertifyReviewModal",
    props: {
        isIssuingCertification: { type: Boolean, required: false, default: false },
        report: { type: Object, required: true, default: () => ({}) },
        previousFinesAndPenalties: { type: Array as PropType<any[]>, required: true, default: () => [] },
    },
    data() {
        const incidents = Array.from(Array(28))
            .map((_, index) => {
                return { key: `principle${index + 1}`, value: `Principle ${index + 1}` };
            })
            .concat([{ key: "others", value: "Others" }]);

        return {
            tabs: [
                { key: "certification", value: "Certification" },
                { key: "fines", value: "Fines & Penalties" },
            ],
            activeTab: "certification",
            penalties: [] as any[],
            incidents,
            isLoading: false,
            isDeleting: false,
        };
    },
    computed: {
        ...mapGetters({
            profileData: "auth/getUserData",
        }),
        penaltiesTotalAmount(): string {
            return Number(
                this.penalties.reduce((accumulator, current: any) => {
                    return Number(accumulator) + Number(current.amount);
                }, 0),
            ).toLocaleString();
        },
        selectedIncidents(): any {
            return this.penalties.map((penalty) => penalty.incidentPrinciple);
        },
        haveModifiedPenalties(): boolean {
            return JSON.stringify(this.previousFinesAndPenalties) !== JSON.stringify(this.penalties);
        },
    },
    mounted() {
        this.penalties = JSON.parse(JSON.stringify([...this.previousFinesAndPenalties]));
    },
    methods: {
        uploadSignature() {
            this.$bvModal.show("modal-upload-signature");
        },

        seeReviewActivity() {
            this.$emit("show-review-options", false);
            this.$bvModal.show("modal-review-activity");
        },

        insertNewPenalty() {
            this.penalties.push({ id: String(Math.floor(Math.random() * 1000000000)), amount: "", basis: "", incidentPrinciple: "", isNew: true });
        },

        async deletePenalty(fineId: string) {
            const { id } = this.$route.params;
            if (this.isLoading || this.isDeleting) return;
            this.isDeleting = true;
            const penaltyIndex = this.previousFinesAndPenalties.findIndex((penalty: any) => penalty.id === fineId);
            if (penaltyIndex > -1) {
                this.$bvToast.toast("Deleting fine", { title: "In progress", solid: true, variant: "info" });
                const response = await ReportService.deleteFine(id, fineId);
                if (response && response.status === 200) {
                    this.$emit("delete-fine", fineId);
                    this.$bvToast.toast("Fine deleted", { title: "Success", solid: true, variant: "success" });
                    this.penalties = this.penalties.filter((penalty) => penalty.id !== fineId);
                }
            } else {
                this.penalties = this.penalties.filter((penalty) => penalty.id !== fineId);
            }
            this.isDeleting = false;
        },

        async savePenalties() {
            this.isLoading = true;
            const { id } = this.$route.params;

            const newPenalties = this.penalties.filter((penalty) => penalty.isNew).map(({ amount, basis, incidentPrinciple }) => ({ basis, amount: Number(amount), incidentPrinciple }));
            const updatedPenalties = this.penalties.filter((penalty) => {
                const penaltyIndex = this.previousFinesAndPenalties.findIndex((oldPenalty: any) => oldPenalty.id === penalty.id);
                const updatedPenalty = this.previousFinesAndPenalties[penaltyIndex];
                return penaltyIndex > -1 && (penalty.basis !== updatedPenalty.basis || penalty.amount !== updatedPenalty.amount || penalty.incidentPrinciple !== updatedPenalty.incidentPrinciple);
            });

            const body = { finesAndPenalties: [...newPenalties, ...updatedPenalties] };

            if (body.finesAndPenalties.length > 0) {
                try {
                    const response = await ReportService.addFines(id, body);
                    if (response && response.status === 200) {
                        this.penalties = this.penalties.map((penalty, index) => {
                            if (penalty.isNew) {
                                const indexOfPenaltyId = index - this.penalties.length + 1 + newPenalties.length - 1;
                                return { id: response.data.ids[indexOfPenaltyId], basis: penalty.basis, amount: penalty.amount, incidentPrinciple: penalty.incidentPrinciple };
                            }
                            return penalty;
                        });
                        this.$emit("update-fines", this.penalties);
                        this.$bvToast.toast("Fines have been applied successfully.", { title: "Success", solid: true, variant: "success" });
                    }
                } catch (error) {
                    this.isLoading = false;
                }
            }
            this.isLoading = false;
        },
    },
    validations() {
        return {
            penalties: {
                required,
                $each: {
                    amount: { required },
                    basis: { required },
                    incidentPrinciple: { required },
                },
            },
        };
    },
});
