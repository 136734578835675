












import Vue from "vue";
import ReviewCount from "./ReviewCount.vue";
import SectionStatus from "./SectionStatus.vue";

export default Vue.extend({
    components: { SectionStatus, ReviewCount },
    props: {
        title: { type: String, required: true },
        reviews: { type: Array, required: true },
        canToggle: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            showReviewList: false,
        };
    },
});
