


























































































































import Vue from "vue";
import principles from "@/assets/providers/principles";
import { romanize, formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";
import { mapActions } from "vuex";

enum IQuestionType {
    TEXT,
    LONGTEXT,
    NUMBER,
    DATEPICKER,
    MEDIA,
    DROPDOWN,
    MULTIPLE_DROPDOWN,
    LINKED_TYPE,
    BOOLEAN,
    BOOLEAN_AND_LONGTEXT,
    BOOLEAN_AND_LONGTEXT_AND_MEDIA,
    LONGTEXT_AND_MEDIA,
    TEXT_AND_DATEPICKER,
    DROPDOWN_AND_DATEPICKER,
    MULTIPLE_DROPDOWN_AND_LONGTEXT,
}

type IInputModelType = {
    attatchments: any;
    booleanValue: boolean;
    dateValue: string | null;
    listValue: any;
    numericValue: number;
    dataType: number;
    stringValue: string | null;
    timeOfAction: string | null;
    uniqueName: string;
    inputIdentifier: string;
};

type IPrincipleType = {
    id: string;
    name: string;
    title: string;
    description: string;
    hasFee: boolean;
    fee: number;
    normalizedName: string;
    isImportant: boolean;
    questions: [
        {
            question: string;
            isRequired: boolean;
            uniqueName: string;
            orderIndex: number;
            innerOrderIndex: number;
            dataType: number;
            selectOptions: [];
            allowedDocType: string[];
            isFlaggable: boolean;
        },
    ];
    orderIndex: number;
};

export default Vue.extend({
    props: {
        inputModel: {
            type: Object as () => IInputModelType,
            required: true,
        },
        index: Number,
        principle: {
            required: true,
            type: Object as () => IPrincipleType,
        },
        showButtons: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            IQuestionType,
            DateTime,
            isLoading: false,
        };
    },
    computed: {
        mappedInputModel(): any {
            return { ...this.inputModel, type: [this.inputModel.dataType] };
        },
        principleInput() {
            const val = principles.find((principle: any) => principle.name === this.principle.name);

            return val.questions.find((question: any) => question.uniqueName === this.inputModel?.inputIdentifier);
        },
    },
    methods: {
        ...mapActions({
            flagPrincipleInput: "reports/flagPrincipleInput",
        }),
        romanize,
        formatDate,
        toggleFlagPrinciple({ flag }: { flag: boolean }) {
            this.$emit("selectQuestion", {
                flag,
                principle: this.principle,
                mappedInputModel: this.mappedInputModel,
            });
        },

        async resolveFlagPrinciple(status: boolean) {
            const body = {
                principleId: this.principle.id,
                inputId: this.mappedInputModel.id,
                isFlagged: status,
                flagReason: "",
            };
            try {
                this.isLoading = true;
                const res = await this.flagPrincipleInput({
                    id: this.$route.params.id as string,
                    body,
                });

                this.$bvToast.toast(res.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$emit("completed", true);
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
    },
});
