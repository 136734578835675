





























import Vue from "vue";
import ReportService from "@/api/report-service";
import ReviewSummary from "../review/ReviewSummary.vue";

export default Vue.extend({
    components: { ReviewSummary },
    props: {
        reviews: { type: Array, required: true, default: () => [] },
    },
    data() {
        return {
            isLoading: false,
            showReviewSummary: false,
        };
    },
    computed: {
        numberOfFlaggedSection(): number {
            return this.reviews.filter((section: any) => section.status === "flagged").length;
        },
        numberOfCheckedSection(): number {
            return this.reviews.filter((section: any) => section.status === "checked").length;
        },
        unreviewedSections(): any {
            return this.reviews.filter((section: any) => section.status === "unreviewed" || (section.status === "flagged" && section.flags.length === 0));
        },
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-complete-review");
        },
        async submitForCertification() {
            if (this.unreviewedSections && this.unreviewedSections.length > 0) {
                this.$bvToast.toast("You cannot submit a report that have not been fully reviewed for certification!", { title: "Something is not right", solid: true, variant: "danger" });
                return;
            }
            const { id } = this.$route.params;
            try {
                this.isLoading = true;
                const response = await ReportService.submitForCertification(id);
                if (response && response.status === 200) {
                    this.$bvToast.toast("Report have been submitted for certification", { title: "Success", solid: true, variant: "success" });
                    this.closeModal();
                    this.$emit("submitted-for-certification");
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
    },
});
