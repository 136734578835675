



























import Vue from "vue";
import PrincipleStatus from "../review/PrincipleStatus.vue";
import ReviewSummary from "../review/ReviewSummary.vue";

export default Vue.extend({
    name: "ReviewActivityModal",
    components: { PrincipleStatus, ReviewSummary },
    props: {
        reviews: { type: Array, required: true },
        showReviewOptions: { type: Boolean, required: false, default: false },
    },
    computed: {
        reviewedPrinciples(): any {
            return this.reviews.filter((review: any) => review.type === "principle");
        },
    },
    data() {
        return {
            tabs: [
                { key: "principles", value: "Principles" },
                { key: "sections", value: "Sections" },
            ],
            activeTab: "principles",
        };
    },
    mounted() {
        this.$root.$on("bv::modal::hide", () => {
            this.$emit("show-review-options", true);
        });
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-review-activity");
            this.$emit("show-review-options", true);
        },

        sendBackToReviewer() {
            this.$bvModal.hide("modal-review-activity");
            this.$bvModal.show("modal-send-back");
        },

        certifyReview() {
            this.$bvModal.hide("modal-review-activity");
            this.$bvModal.show("modal-certify-review");
        },
    },
});
