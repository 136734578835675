





































import ReportService from "@/api/report-service";
import Vue from "vue";

export default Vue.extend({
    props: {
        type: { type: String, required: false, default: "section" },
        usage: { type: String, required: false, default: "flag" }, // 'flag', 'view-flags'
        sectionData: { type: Object, required: false, default: () => ({}) },
        isLoading: { type: Boolean, required: false, default: false },
    },
    computed: {
        modalTitle(): string {
            if (this.usage === "flag") return `Flag ${this.sectionData.type}`;
            return `See reason(s)`;
        },
        modalDescription(): string {
            if (this.usage === "flag") return `Flag this ${this.sectionData.type} and include your reason(s)`;
            return `See the reason(s) why this ${this.sectionData.type} was flagged.`;
        },
        haveAnEmptyReviewComment(): boolean {
            return this.flags.length === 0 || this.flags.some((flag) => flag.details === "");
        },
    },
    data() {
        return {
            flags: [] as any[],
            isDeleting: false,
        };
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-flag-section-or-principle");
        },
        addNewFlag() {
            this.flags.push({ id: String(Math.floor(Math.random() * 10000000)), details: "", isNew: true });
        },
        async deleteFlag(flagId: string) {
            if (this.isLoading || this.isDeleting) return;
            this.isDeleting = true;
            const { id } = this.$route.params;
            const flagIndex = this.sectionData.flags.findIndex((flag: any) => flag.id === flagId);
            if (flagIndex > -1) {
                this.$bvToast.toast("Deleting flag", { title: "In progress", solid: true, variant: "info" });
                const response = await ReportService.deleteFlag(id, flagId);
                if (response && response.status === 200) {
                    this.$bvToast.toast("Flag deleted", { title: "Success", solid: true, variant: "success" });
                    this.$emit("delete-flag", { sectionId: this.sectionData.id, flagId });
                    this.flags = this.flags.filter((flag) => flag.id !== flagId);
                }
            } else {
                this.flags = this.flags.filter((flag) => flag.id !== flagId);
            }
            this.isDeleting = false;
        },
        saveFlags() {
            if (this.flags.length === 0) {
                return;
            }
            this.$emit("save-flags", { id: this.sectionData.id, flags: this.flags });
        },
    },
    watch: {
        sectionData: {
            handler(value) {
                const sectionHaveFlags = value.flags && value.flags.length > 0;
                if (sectionHaveFlags) {
                    this.flags = JSON.parse(JSON.stringify(value.flags));
                } else {
                    this.flags = []
                }
            },
            deep: true,
        },
    },
});
