


















import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
    name: "UploadSignatureModal",
    data() {
        return {
            file: null as File | null,
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            profileData: "auth/getUserData",
        }),
    },
    methods: {
        ...mapActions({
            uploadFile: "file/uploadFile",
            updateProfile: "auth/updateProfile",
            updateSignature: "auth/updateSignatureImageId",
        }),

        closeModal() {
            this.$bvModal.hide("modal-upload-signature");
        },

        async uploadSignature() {
            this.isLoading = true;
            try {
                const formData = new FormData();
                formData.append("file", this.file as Blob);
                const response = await this.uploadFile(formData);
                if (response && response.success) {
                    await this.updateProfile({
                        ...this.profileData,
                        signatureImageId: response.fileName,
                    });
                    this.updateSignature(response.fileName);
                    this.closeModal();
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },

        selectImage() {
            (this.$refs.image as HTMLInputElement).click();
        },

        async handleSignatureUpload(event: Event) {
            const { files } = event.target as HTMLInputElement;
            if (files && files[0]) {
                await this.$nextTick();
                const imageRef = this.$refs.imageRef as HTMLImageElement;
                imageRef.onload = () => {
                    URL.revokeObjectURL(imageRef.src);
                };
                imageRef.src = URL.createObjectURL(files[0]);
                imageRef.classList.add("d-block");
                const [file] = files;
                this.file = file;
            }
        },
    },
});
