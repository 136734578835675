




















import Vue from "vue";

export default Vue.extend({
    name: "ViewMessageModal",
    props: {
        comment: { type: Object, required: false, default: () => ({}) },
    },
    computed: {
        userData(): any {
            return { firstName: this.comment?.firstname ?? "", lastName: this.comment?.lastname ?? "", email: this.comment?.email ?? "", profileImgId: this.comment?.imageId ?? "" };
        },
        certifierComment(): string {
            return this.comment?.comment ?? "";
        },
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-view-message");
        },
    },
});
