



























import Vue from "vue";

export default Vue.extend({
    name: "PrincipleStatus",
    props: {
        principle: { type: Object, required: true },
    },
    computed: {
        numberOfPrincipleFlags(): number {
            return this.principle.flags.length;
        },
    },
});
