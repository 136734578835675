
























































import Vue from "vue";
import ReportSection from "@/app/components/tabs/view-report/Section.vue";

export default Vue.extend({
    components: { ReportSection },
    props: {
        isLoading: { type: Boolean, default: false },
        viewMode: { type: String, required: true },
        isSaving: { type: Boolean, required: false, default: false },
        report: { type: Object, default: () => ({}) },
        haveStartedReviewing: { type: Boolean, default: false },
        reviews: { type: Array, required: true, default: () => [] },
    },
    computed: {
        sectionData(): any {
            const section = this.reviews.find((review: any) => review.id === "governanceStructures") as any;
            return section;
        },
        sectionIsFlagged(): boolean {
            return this.sectionData.status === "flagged" && this.sectionData.flags.length > 0;
        },
        sectionIsChecked(): boolean {
            return this.sectionData.status === "checked";
        },
        numberOfFlags(): number {
            return this.sectionData.flags.length;
        },
    },
});
